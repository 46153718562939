<template>
  <div class="all-user-container bg-white">
    <div class="form-action">
      <!-- filter desktop -->
      <div class="filter-desktop balance-allowcation">
        <!-- filter 1 -->
        <b-row class="input-control">
          <b-col
            md="7"
            lg="5"
            class="input-height"
          >
            <div>
              <b-form-group>
                <b-input-group class="input-group-merge">
                  <b-input-group-prepend is-text>
                    <feather-icon icon="SearchIcon" />
                  </b-input-group-prepend>
                  <b-form-input
                    v-model.trim="searchTerm"
                    :placeholder="$t('partnerView.placeholderSearchRedeemCode')"
                    class="d-inline-block input-height"
                    @input="handleSearch"
                  />
                </b-input-group>
              </b-form-group>
            </div>
          </b-col>

          <b-col
            offset-md="4"
            offset-lg="1"
            offset-xl="2"
            md="5"
            lg="4"
            xl="3"
            class="input-height mt-1 mt-lg-0"
          >
            <div class="d-flex justify-content-center">

              <calendar
                v-model="rangeDate"
                @input="onSelect"
              />
            </div>
          </b-col>

          <b-col
            md="3"
            lg="2"
            class="pl-md-0 mt-1 mt-lg-0 input-height"
          >
            <div class="status-input input-height">
              <v-select
                v-model="statusSelected"
                class="input-height"
                label="name"
                :options="statusOptions"
                :clearable="false"
                @input="handleStatusChange"
              >
                <template #open-indicator="{ attributes }">
                  <span v-bind="attributes">
                    <feather-icon
                      icon="ChevronDownIcon"
                      size="20"
                    />
                  </span>
                </template>
              </v-select>
            </div>
          </b-col>
        </b-row>
      </div>
      <!-- /filter desktop -->
    </div>
    <!-- table -->
    <vue-good-table
      class="vgt-no-border table-gift-code-management"
      mode="remote"
      :columns="columns"
      :rows="listGiftCode"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm,
      }"
      :pagination-options="{
        enabled: totalGiftCode > 0,
      }"
      :sort-options="{
        enabled: totalGiftCode > 0,
      }"
      :is-loading="loading"
      @on-sort-change="onSortChange"
    >
      <template
        slot-scope="props"
        slot="table-row"
      >
        <!-- Column: Ad Account -->
        <div
          v-if="props.column.field === 'value'"
          class="d-flex flex-column"
        >
          <span>${{ props.row.value }}</span>
        </div>

        <!-- Column: savedQuantity -->
        <div
          v-else-if="props.column.field === 'savedQuantity'"
          class="d-flex flex-column"
        >
          <span>{{ savedQuantity(props.row) }}</span>
        </div>

        <!-- Column: paidQuantity -->
        <div
          v-else-if="props.column.field === 'paidQuantity'"
          class="d-flex flex-column"
        >
          <span>{{ paidQuantity(props.row) }}</span>
        </div>

        <span v-else-if="props.column.field === 'startedAt'">
          <div v-if="props.row.startedAt">
            {{ createdDate(props.row.startedAt) }}
          </div>
        </span>

        <span v-else-if="props.column.field === 'endedAt'">
          <div v-if="props.row.endedAt">
            {{ createdDate(props.row.endedAt) }}
          </div>
        </span>

        <span
          v-else-if="props.column.field === 'minSpend'"
          class="content-condition"
        >
          <ul>
            <li>{{ $t('partnerView.textMinTotalSpend') }}: ${{ props.row.minSpend }}</li>
            <li>{{ $t('partnerView.textMaxTotalSpend') }}: ${{ props.row.maxSpend }}</li>
          </ul>
        </span>

        <!-- Column: timeOfUse -->
        <div
          v-else-if="props.column.field === 'timeOfUse'"
          class="content-condition"
        >
          <p class="m-0">
            {{ timeOfUse(props.row) }}
          </p>
        </div>

        <!-- Column: minimumSpending -->
        <div
          v-else-if="props.column.field === 'minimumSpending'"
          class="content-condition"
        >
          <p class="m-0">
            {{ minimumSpending(props.row) }}
          </p>
        </div>

        <!-- Column: Status -->
        <span
          v-else-if="props.column.field === 'status'"
          class="position-relative"
        >
          <b-badge
            class="text-capitalize status-gift-code"
            :variant="statusVariant(props.row.status)"
            :class="props.row.status"
          >
            <span class="content-status">{{ multiStatus(props.row.status) }}</span>
          </b-badge>
        </span>

        <span
          v-else-if="props.column.field === 'copy'"
          class="content-condition"
        >
          <b-button
            variant="outline-dark"
            pill
            @click="handleCopy(props.row.code)"
          >
            {{ $t('partnerView.btnCopyCode') }}
          </b-button>
        </span>

        <!-- Column: common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <!-- pagination -->
      <template
        v-if="totalGiftCode > 0"
        slot="pagination-bottom"
      >
        <div class="d-flex flex-wrap footer-tab">
          <b-col
            md="6"
            class="px-0 d-flex align-items-center"
          >
            <span
              class="text-secondary font-14"
            >{{ totalGiftCode }} {{ $t('profile.giftCodeTab.textGiftCode') }}</span>
          </b-col>
          <!-- page length -->
          <b-col
            v-if="totalGiftCode > pageLength"
            md="6"
            class="px-0"
          >
            <b-pagination
              :total-rows="totalGiftCode"
              :value="currentPage"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mb-0 pagination-table"
              @change="handleChangePage"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </div>
      </template>

      <!-- custom loading  -->
      <template slot="loadingContent">
        <b-spinner
          variant="primary"
          type="grow"
          small
        />
      </template>

      <div
        slot="emptystate"
        class="text-center"
      >
        <no-data-table-image class="my-5" />
      </div>
    </vue-good-table>
  </div>
</template>
<script>
/* eslint-disable no-underscore-dangle */
import {
  BSpinner,
  BPagination,
  BInputGroup,
  BInputGroupPrepend,
  BFormInput,
  BBadge,
  BButton,
  BRow,
  BCol,
  BFormGroup,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import {
  DATE_RANGE, STATUS_GIFT_CODE,
} from '@/constants'
import numberFormatMixin from '@/mixins/numberFormatMixin'
import { toastification } from '@core/mixins/toast'
import stepGuideMixin from '@/mixins/stepGuideMixin'
import _get from 'lodash/get'

import { createNamespacedHelpers } from 'vuex'
import { VueGoodTable } from 'vue-good-table'
import NoDataTableImage from '@/components/NoDataTableImage.vue'
import Calendar from '@/components/Calendar.vue'
import envMixin from '@/mixins/envMixin'

const {
  mapActions,
  mapGetters,
} = createNamespacedHelpers('giftCode')
const { mapGetters: mapGettersUser } = createNamespacedHelpers('auth')

export default {
  name: 'GiftCodePartner',
  components: {
    BSpinner,
    BPagination,
    BInputGroup,
    BInputGroupPrepend,
    BBadge,
    BFormInput,
    VueGoodTable,
    NoDataTableImage,
    vSelect,
    BButton,
    BRow,
    BCol,
    BFormGroup,

    // component
    Calendar,
  },
  mixins: [numberFormatMixin, toastification, stepGuideMixin, envMixin],

  data() {
    return {
      copySuccess: false,

      currentPage: 1,
      searchTerm: '',

      // sort
      sortTerm: {
        field: '',
        type: '',
      },

      // per page
      pageLength: 10,
      pages: ['5', '10', '20'],

      // date picker
      rangeDate: {
        startDate: null,
        endDate: null,
      },

      // search Dropdown
      searchOptions: [
        {
          text: 'User ID',
          value: 'localUserId',
        },
        {
          text: 'User email',
          value: 'email',
        },
      ],

      // status filter
      statusSelected: {
        name: this.$t('payment.balanceTransactionsTable.textAllStatus'),
        value: null,
      },
      statusOptions: [
        {
          name: this.$t('payment.balanceTransactionsTable.textAllStatus'),
          value: null,
        },
        {
          name: this.$t('payment.textActive'),
          value: STATUS_GIFT_CODE.ACTIVE,
        },
        {
          name: this.$t('profile.giftCodeTab.statusDeactivate'),
          value: STATUS_GIFT_CODE.DEACTIVATE,
        },
        {
          name: this.$t('payment.creditCard.labelExpired'),
          value: STATUS_GIFT_CODE.EXPIRED,
        },
      ],

      // week, month, year filter
      dateSelected: null,
      dateOptions: DATE_RANGE,

      columns: [
        {
          label: this.$t('profile.giftCodeTab.textRedeemGiftCode'),
          field: 'code',
          thClass: 'p-1 border-0 header-table-mf width-th-150',
          tdClass:
            'p-1 border-top-0 border-left-0 border-right-0 border-bottom-1 align-middle',
        },
        {
          label: this.$t('profile.giftCodeTab.textWorthGift'),
          field: 'value',
          thClass: 'p-1 border-0 header-table-mf',
          tdClass:
            'p-1 border-top-0 border-left-0 border-right-0 border-bottom-1 align-middle th-worth-gift',
        },
        {
          label: this.$t('partnerView.textSavedQuantity'),
          field: 'savedQuantity',
          thClass: 'p-1 border-left-0 border-right-0 header-table-mf width-th-150',
          tdClass: 'p-1 border-left-0 border-right-0 align-middle',
        },
        {
          label: this.$t('partnerView.textPaidQuantity'),
          field: 'paidQuantity',
          thClass: 'p-1 border-left-0 border-right-0 header-table-mf width-th-150',
          tdClass: 'p-1 border-left-0 border-right-0 align-middle',
        },
        {
          label: this.$t('partnerView.textStart'),
          field: 'startedAt',
          thClass: 'p-1 border-0 header-table-mf',
          tdClass:
            'p-1 border-top-0 border-left-0 border-right-0 border-bottom-1 align-middle',
        },
        {
          label: this.$t('payment.creditCard.labelExpired'),
          field: 'endedAt',
          thClass: 'p-1 border-0 header-table-mf',
          tdClass:
            'p-1 border-top-0 border-left-0 border-right-0 border-bottom-1 align-middle',
        },
        {
          label: this.$t('partnerView.textCondition'),
          field: 'minSpend',
          thClass: 'p-1 border-0 header-table-mf',
          tdClass:
            'p-1 border-top-0 border-left-0 border-right-0 border-bottom-1 align-middle',
        },
        {
          label: this.$t('partnerView.textTimeOfUseWithin'),
          field: 'timeOfUse',
          thClass: 'p-1 border-left-0 border-right-0 header-table-mf th-time-of-use',
          tdClass: 'p-1 border-left-0 border-right-0 align-middle',
        },
        {
          label: this.$t('profile.giftCodeTab.textMinimumRequiredSpending'),
          field: 'minimumSpending',
          thClass: 'p-1 border-left-0 border-right-0 header-table-mf th-time-of-use',
          tdClass: 'p-1 border-left-0 border-right-0 align-middle',
        },
        {
          label: this.$t('account.status'),
          field: 'status',
          thClass: 'p-1 border-0 header-table-mf',
          tdClass:
            'p-1 border-top-0 border-left-0 border-right-0 border-bottom-1 align-middle',
        },
        {
          label: '',
          field: 'copy',
          thClass: 'p-1 border-0 header-table-mf',
          tdClass:
            'p-1 border-top-0 border-left-0 border-right-0 border-bottom-1 align-middle',
        },
      ],
    }
  },

  computed: {
    ...mapGetters(['listGiftCodePartner', 'message', 'status', 'loading']),
    ...mapGettersUser(['user']),

    savedQuantity() {
      return giftCode => `${giftCode.savedQuantity || 0}/${giftCode.quantity || 0}`
    },

    paidQuantity() {
      return giftCode => `${giftCode.paidQuantity || 0}/${giftCode.quantity || 0}`
    },

    minimumSpending() {
      return giftCode => this.spendGiftCodeFormat(giftCode.minimumSpending) || 'N/A'
    },

    timeOfUse() {
      return giftCode => giftCode.timeOfUse || 'N/A'
    },

    idPartner() {
      return _get(this.user, ['data', '_id'], '')
    },

    listGiftCode() {
      return _get(this.listGiftCodePartner, ['content'], [])
    },

    totalGiftCode() {
      return _get(this.listGiftCodePartner, ['paging', 'total'], [])
    },

    spendGiftCodeFormat() {
      return amount => amount?.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
      })
    },

    createdDate() {
      return value => (value ? new Date(value).toLocaleString('en-GB') : '')
    },

    statusVariant() {
      const statusColor = {
        [STATUS_GIFT_CODE.SAVED]: 'saved',
        [STATUS_GIFT_CODE.PAID]: 'paid',
        [STATUS_GIFT_CODE.EXPIRED]: 'expired',
        [STATUS_GIFT_CODE.ACTIVE]: 'active',
        [STATUS_GIFT_CODE.DEACTIVATE]: 'deactivate',
      }

      return status => statusColor[status]
    },

    multiStatus() {
      const statusTxt = {
        [STATUS_GIFT_CODE.SAVED]: this.$t('profile.giftCodeTab.statusSaved'),
        [STATUS_GIFT_CODE.PAID]: this.$t('billingPage.statusDone'),
        [STATUS_GIFT_CODE.EXPIRED]: this.$t('profile.giftCodeTab.statusExpired'),
        [STATUS_GIFT_CODE.ACTIVE]: this.$t('profile.giftCodeTab.statusActive'),
        [STATUS_GIFT_CODE.DEACTIVATE]: this.$t('profile.giftCodeTab.statusDeactivate'),
      }

      return status => statusTxt[status]
    },

    rangeDates() {
      return {
        startedAt: this.rangeDate.startDate,
        endedAt: this.rangeDate.endDate
          ? this.rangeDate.endDate
          : this.rangeDate.startDate,
      }
    },
  },

  watch: {
    user: {
      handler(userInfo) {
        if (userInfo?.data?._id) {
          this.fetchListGiftCodePartner()
        }
      },
      deep: true,
      immediate: true,
    },
  },

  methods: {
    ...mapActions(['getListGiftCodePartner']),

    async onSortChange(params) {
      this.sortTerm.field = params[0].field
      this.sortTerm.type = params[0].type
      await this.fetchListGiftCodePartner()
    },

    onSelect(val) {
      if (val) {
        this.fetchListGiftCodePartner()
      }
    },

    async handleStatusChange() {
      this.currentPage = 1
      await this.fetchListGiftCodePartner()
    },

    async handleSearch(searching) {
      if (this.debounce !== null) {
        await clearTimeout(this.debounce)
      }
      if (searching === '') {
        this.clearSearch()
      } else if (searching.length > 1) {
        this.currentPage = 1
        this.debounce = setTimeout(() => {
          this.fetchListGiftCodePartner()
        }, 200)
      }
    },

    clearSearch() {
      this.searchTerm = ''
      this.currentPage = 1
      this.fetchListGiftCodePartner()
    },

    async handleChangePage(page) {
      this.currentPage = page
      await this.fetchListGiftCodePartner()
    },

    async fetchListGiftCodePartner() {
      const params = {
        id: this.idPartner,
        page: this.currentPage,
        size: this.pageLength,
        ...(this.statusSelected?.value && { status: this.statusSelected.value }),
        ...(this.searchTerm && { code: this.searchTerm }),
        ...this.rangeDates,
      }
      await this.getListGiftCodePartner(params)
      if (!this.status) {
        this.toastFailure(this.message)
      }
    },

    handleCopy(code) {
      this.$copyText(code)
        .then(() => {
          this.copySuccess = true
        })
      setTimeout(() => {
        this.copySuccess = false
      }, 10)
      if (!this.copySuccess) {
        this.toastSuccess(this.$t('partnerView.textCodeCopied'))
      } else {
        this.toastFailure(this.$t('partnerView.textCopyCodeFailed'))
      }
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "@core/scss/vue/libs/vue-select.scss";

.table-gift-code-management{
  .width-th-150{
    min-width: 170px !important;
    width: max-content;
  }
}

.ads-avt-img {
  width: 44px;
  height: 44px;
  margin-right: 10px;
}

.close-icon {
  .input-group-text {
    padding-left: 0;
    padding-right: 0;
  }
}

.select-search-custom {
  border-right: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.form-action {
  .currency {
    .vs__dropdown-toggle {
      height: 100%;
      border-radius: 12px;
    }
  }

  .product-tag {
    .b-form-tags {
      padding: 0;
      border: none;
    }

    .card {
      border-radius: 0.625rem;
    }

    .card-body {
      padding: 0.86rem 1rem;
    }
  }

  .input-height {
    .vs__dropdown-toggle {
      border: 1px solid #e0e0e5;
    }
  }
}

.th-worth-gift{
  min-width: 150px !important;
}

.th-time-of-use{
  min-width: 150px !important;
}
</style>

<style lang="scss" scoped>
.productUrl {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;

  .url {
    text-decoration: underline;
    color: #16213eb2;
  }
}

.all-user-container {
  border-radius: var(--border-radius-lg);

  .form-action {
    padding: 21px 24px;

    &.vs__dropdown-toggle {
      height: 40px;
    }
  }

  .form-group {
    margin-bottom: 0;
  }
}

.input-height {
  height: 40px;
}

.footer-tab {
  padding: 27px 24px;
}

.status-gift-code {
  padding: 5px 12px;
  border-radius: 100px;
  width: max-content;

  .content-status {
    margin: 0;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: bold;
  }

  &.done {
    color: #1db9c3;
    background-color: #1db9c31a;
  }

  &.paid {
    background: rgba(76, 179, 117, 0.1);
    color: #4CB375;
  }

  &.saved {
    background: rgba(255, 153, 3, 0.1);
    color: #FF9903;
  }

  &.expired {
    background: rgba(0, 0, 0, 0.05);
    color: rgba(0, 0, 0, 0.5);
  }

  &.deactivate {
    background: rgba(225, 28, 78, 0.1);
    color: #E11C4E;
  }
}

.content-condition {
  width: max-content;
  display: flex;
  align-items: center;
}
</style>
